.custom-input {
  margin-top: 10px;
  position: relative;
  display: inline-flex;
  align-items: center;

  height: 30px;
  padding: 0 12px;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.custom-input input {
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.custom-input input::placeholder {
  color: rgba(0, 0, 0, 0.54);
}

.custom-input input:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.custom-input input:focus {
  background-color: #fff;
  box-shadow: 0 0 0 2px #1890ff;
}

.label {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 13px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  margin-bottom: 20px;
}
